/** @format */

import { useState, useRef } from "react";
import stl from "./Input.module.css";

const Input = ({
  type,
  name,
  id,
  value,
  autoComplete,
  autoFocus,
  disabled,
  maxLength,
  minLength,
  className,
  placeholder,
  extraInfo,
  error,
  onlyNumbers,
  topLabel,
  smallerWidth,
  note,
  inputTitle,
  label,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const ref = useRef();

  const isTargeted =
    isFocused || (value || value === 0 ? true : false) || placeholder;

  const handleChange = (e) => {
    const val = String(e.target.value);
    if (onlyNumbers && !val.match(/^[0-9,]*$/)) return;
    //if (maxLength === val) return;
    if (val.length > maxLength) {
      // If value exceeds maxLength, truncate it
      e.target.value = val.slice(0, maxLength);
    }
    onChange(e);
  };

  const handleTextClick = () => {
    if (isTargeted) return;
    ref.current.focus();
  };

  return (
    <div
      className={`${stl.inputWrapper} ${isTargeted ? stl.targeted : ""} ${
        error ? stl.hasError : ""
      } ${className ? className : ""} ${isFocused ? stl.focused : ""} ${
        disabled ? stl.disabled : ""
      }`}
    >
      <input
        ref={ref}
        type={
          type === "number"
            ? "number"
            : type === "password" && !showPassword
            ? "password"
            : "text"
        }
        name={name}
        id={id}
        value={value}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        onKeyDown={(e) => {
          if (type === "number" && (e.key === "e" || e.key === "E"))
            return e.preventDefault();
        }}
        onChange={handleChange}
        onFocus={() => {
          onFocus();
          setIsFocused(true);
        }}
        onBlur={() => {
          onBlur();
          setIsFocused(false);
        }}
        placeholder={placeholder}
      />
      <span
        onClick={() => {
          !value && handleTextClick();
        }}
        className={stl.label}
      >
        {label}
      </span>
      {topLabel && (
        <span className={stl.topLabel} onClick={handleTextClick}>
          {topLabel}
        </span>
      )}
      {extraInfo && (
        <span onClick={handleTextClick} className={stl.extraInfo}>
          {extraInfo}
        </span>
      )}
      {type === "password" && (
        <img
          src={
            !showPassword
              ? "/assets/images/visibilityEye.svg"
              : "/assets/images/visibilityEyeBlue.svg"
          }
          alt="toggle-password"
          className={`${stl.togglePassword} hasEvents`}
          onClick={() => setShowPassword((pre) => !pre)}
        />
      )}
      {error && <span className={stl.error}>{error}</span>}
      {!error && note && <span className={stl.note}>{note}</span>}
    </div>
  );
};

export default Input;
