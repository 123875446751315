import { CDN_LINK } from "@globalConfig";
import SubmitButton from "../../Buttons/SubmitButton/SubmitButton";

import stl from "./OtpLimitExceeded.module.css";

const OtpLimitExceeded = ({ onClick, className }) => {
  return (
    <div className={`${stl.wrapper} ${className ? className : ""}`}>
      <img src={CDN_LINK + "/assets/images/time-bomb.svg"} alt="" />
      <strong>يرجى المحاولة بعد 24 ساعة</strong>
      <p>
        لقد تجاوزت الحد المسموح به من محاولات اضافة رمز التحقق . يرجى إعادة
        المحاولة بعد 24 ساعة.
      </p>
      <SubmitButton type="button" onClick={onClick}>
        العودة للرئيسية
      </SubmitButton>
    </div>
  );
};

export default OtpLimitExceeded;
