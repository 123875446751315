/** @format */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "@i18n";
import AuthForm from "./AuthForm/AuthForm";
import Otp from "../UiKit/Otp";
import Loader from "../sharedComponents/Loader";
import BackButton from "../UiKit/Buttons/BackButton/BackButton";
import {
  customerAuth,
  togglePostToFavorite,
} from "../../redux/actions/userActions";
import { getAuthBanner, setUserData } from "../../redux/actions/authActions";
import useValidate from "../../common/hooks/useValidate";

import stl from "./AuthComponent.module.css";
import { oldUserIdPropertyEvent } from "../../utils/dataLayerEvents";
import OtpLimitExceeded from "../UiKit/Otp/OtpLimitExceeded/OtpLimitExceeded";
import {
  createInternalUrlWithParams,
  handleHasAccountEvent,
} from "../../utils/common";
import { sendOtp } from "../../redux/actions/commonActions";
import { trackEvents } from "../../common/events/events";
import { useLang } from "../../common/Contexts/LanguageContext";
import { setAccessToken, setUserToLocalStorage } from "../../utils/auth";
import useIsMobile from "@hooks/useIsMobile";

const AuthComponent = ({
  isPopUp,
  popUpVerifyCb,
  isMyAccount,
  referral_location,
}) => {
  const lang = useLang();
  const isMobile = useIsMobile();
  const postLoading = useSelector(({ common }) => common.footerLoading);
  const loading = useSelector(({ common }) => common.loading);
  const { loggedin, authData, authLoading } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["web_auth", "web_common"]);
  const { validatePhoneNumber, validateName } = useValidate();
  const { carCondition, isOnline, page, postId, categoryId, event } =
    useParams();

  const [searchParams] = useSearchParams();
  const referralLocParam = searchParams.get("referralLocation");
  const referralLocProps = referral_location;
  const [showOtp, setShowOtp] = useState(false);
  useState(false);
  const [generalError, setGeneralError] = useState("");
  const [fields, setFields] = useState({
    full_name: { value: "", error: "" },
    phone_number: { value: "", error: "" },
    otp: { value: "", error: "" },
  });
  const [isOtpLimitExceeded, setIsOtpLimitExceeded] = useState(false);
  const [hasAccount, setHasAccount] = useState(false);
  const handleFieldsChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone_number" && value.length > 10) return;

    setFields((pre) => ({ ...pre, [name]: { error: "", value: value } }));
  };
  const handleSetErrors = (errors, isVerified) => {
    if (isVerified === false) return setShowOtp(true);

    if (errors.general) setGeneralError(errors.general);

    return setFields((pre) => {
      let nextState = { ...pre };

      Object.entries(errors).forEach((error) => {
        const name = error[0];
        const value = error[1];

        nextState = {
          ...nextState,
          [name]: { ...nextState[name], error: value },
        };
      });

      return nextState;
    });
  };

  const validate = () => {
    const errors = {};
    errors.phone_number = validatePhoneNumber(fields.phone_number.value);
    errors.full_name = validateName(fields.full_name.value);

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validate();

    if (errors.phone_number) return handleSetErrors(errors);

    dispatch(
      customerAuth(
        fields.phone_number.value,
        (data) => {
          setHasAccount(data.hasAccount);
          dispatch(
            sendOtp({
              phoneNumber: fields.phone_number.value,
              cb: () => {
                setShowOtp(true);
              },
              fcb: (errors) => {
                if (errors.send_count) {
                  setIsOtpLimitExceeded(true);
                }
              },
              otpLength: 6,
            })
          );
        },
        handleSetErrors
      )
    );
  };

  const handleOtpChange = (val) => {
    setFields((pre) => ({
      ...pre,
      otp: { ...pre["otp"], value: val, error: "" },
    }));
  };

  const setOtpErrors = (error) => {
    handleHasAccountEvent({
      hasAccount,
      status: "Fail",
      phone_number: fields?.phone_number?.value,
      lang,
    });
    setFields((pre) => ({
      ...pre,
      otp: { ...pre["otp"], error: error },
    }));
  };
  const verifyOtpCb = (data) => {
    setAccessToken(data.token);
    setUserToLocalStorage(data);
    dispatch(setUserData(data));
    handleHasAccountEvent({
      hasAccount,
      status: "Success",
      phone_number: fields?.phone_number?.value,
      lang,
      referral_location: referralLocParam ? referralLocParam : referralLocProps,
    });

    oldUserIdPropertyEvent(data.phone_hashed, "login");

    if (page === "search" || page === "postView") {
      dispatch(
        togglePostToFavorite(
          postId,
          isOnline,
          page,
          navigate,
          loggedin,
          (isFav) => {
            if (isFav === true) {
              trackEvents("add_favorite", { post_id: postId });
            }
          }
        )
      );
      return;
    }
    if (searchParams.get("returnUrl")) {
      navigate(createInternalUrlWithParams(searchParams.get("returnUrl")));
      return;
    }
    if (page === "tracking") {
      navigate(createInternalUrlWithParams("/tracking-landing"));
      return;
    }

    if (page === "finance") {
      navigate(createInternalUrlWithParams(`/rajhi/${postId}`));
      return;
    }

    if (popUpVerifyCb) return popUpVerifyCb();
    if (isPopUp) return;
    if (event === "add_favorites" || isMyAccount) return;

    navigate("/user/account/my-info");
  };

  const onEditNumber = () => {
    setShowOtp(false);
    setFields((pre) => ({ ...pre, otp: { value: "", error: "" } }));
  };

  useEffect(() => {
    if (loggedin && !isPopUp) navigate("/");

    dispatch(getAuthBanner(categoryId ? categoryId : 4, carCondition));

    window.addEventListener("popstate", (e) => {
      if (page === "tracking") {
        navigate(createInternalUrlWithParams("/"));
      }
    });

    return () => {
      setShowOtp(false);
      setGeneralError("");
      setFields({
        full_name: { value: "", error: "" },
        phone_number: { value: "", error: "" },
        otp: { value: "", error: "" },
      });
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      const header = document.querySelector(".siteHeader");

      !showOtp
        ? header.classList.add("transparentHeader")
        : header.classList.remove("transparentHeader");
    }
  }, [showOtp]);

  if (authLoading) return <Loader />;

  if (isOtpLimitExceeded)
    return (
      <OtpLimitExceeded
        onClick={() => navigate(createInternalUrlWithParams("/"))}
      />
    );

  const renderer = () => {
    if (showOtp && isOtpLimitExceeded)
      return (
        <OtpLimitExceeded
          onClick={() => navigate(createInternalUrlWithParams("/"))}
        />
      );

    if (showOtp)
      return (
        <Otp
          phoneNumber={fields.phone_number.value}
          otp={fields.otp.value}
          otpError={fields.otp.error}
          otpLength={6}
          scenario={2}
          loading={postLoading}
          setOtpErrors={setOtpErrors}
          verifyOtpCb={verifyOtpCb}
          onOtpChange={handleOtpChange}
          onEditNumber={onEditNumber}
          className={stl.VerifyOtp}
          btnText={t("تأكيد الرمز", {
            ns: "web_common",
          })}
          event={event}
        />
      );

    return (
      <AuthForm
        fields={fields}
        handleFieldsChange={handleFieldsChange}
        onSubmit={handleSubmit}
        postLoading={postLoading}
        generalError={generalError}
        data={authData}
        t={t}
        loading={loading}
      />
    );
  };

  return (
    <div className={`container ${stl.wrapper} ${isPopUp ? stl.popUp : ""}`}>
      <div className={stl.imgWrapper}>
        {(page === "search" || page === "postView" || page === "tracking") &&
          !showOtp && (
            <BackButton
              onClick={() => {
                if (page === "tracking")
                  return navigate(createInternalUrlWithParams("/"));
                navigate(-1);
              }}
              className={`${stl.backBtn} `}
            >
              {t("رجوع", {
                ns: "web_common",
              })}
            </BackButton>
          )}

        <img
          alt="banner"
          src={
            isMobile
              ? authData?.banner?.banner_img_mobile
              : authData?.banner?.banner_img_desktop
          }
        />
      </div>

      {renderer()}
    </div>
  );
};

export default AuthComponent;
