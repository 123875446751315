import { apiUrl } from "@globalConfig";
import searchFetcher from "@/common/fetchSearchServices";
import { IPostDetails } from "../../../types/models/posts";


export const getSimilarPosts = async (postId: number, size: number | null = null): Promise<IPostDetails> => {
  const params = {
    post_id: postId,
    size: size !== null ? size : undefined // If size is provided, include it in the params
  };
  const queryString = Object.entries(params)
    .filter(([_, value]) => value !== undefined) // Remove undefined values
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const res = await searchFetcher().get(`/similar-items/si_1?${queryString}`);
  return res?.data?.data || null;
};
