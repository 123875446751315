import fetcher from "../../common/axiosInstance";
import {
  AUTH_LOADING,
  LOGOUT_USER,
  SET_AUTH_DATA,
  SET_USER_DATA,
} from "../types";
import {getUserFromLocalStorage, setUserToLocalStorage} from "../../utils/auth";

export const authLoading = (value) => ({
  type: AUTH_LOADING,
  payload: value,
});
/** LoginUser Action */

export const setUserData = (newData) => {
  const defaultUserData = getUserFromLocalStorage() || {};
  let combinedData = newData === null ? null: { ...defaultUserData, ...newData };
  setUserToLocalStorage(combinedData);
  return {
    type: SET_USER_DATA,
    payload: combinedData,
  };
};

export const logoutUser = (CB) => async (dispatch) => {
  localStorage.removeItem("user");
  dispatch({
    type: LOGOUT_USER,
  });
  CB && CB();
};

export const getAuthBanner = (catId, isNew) => async (dispatch) => {
  dispatch(authLoading(true));
  const data = new FormData();

  if (catId) data.append("category", catId);
  if (isNew) data.append("is_new", isNew);
  const res = await fetcher().post("/customer/get-banners-new", data);

  if (res?.data?.code === 200)
    dispatch({ type: SET_AUTH_DATA, payload: res.data.data });

  dispatch(authLoading(false));
};
