import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { CDN_LINK } from "@globalConfig";
import AuthComponent from '../AuthComponent';
import stl from './AuthPopUp.module.css';
import useIsBrowser from "@hooks/useIsBrowser";


const AuthPopUp = ({
  show,
  onClose,
  categoryId,
  carCondition = 1,
  popUpVerifyCb = () => {},
  event,
}) => {
  const { t } = useTranslation(['web_common']);
  const canUseDOM = useIsBrowser();

  const [isLogin, setIsLogin] = useState(true);

  const popUp = (
    <>
      {show && (
        <div
          className={stl.authPopUpPortal}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <img
            src={CDN_LINK + "/assets/images/white-thin-x.deff0857.svg"}
            alt="close"
            className={`${stl.close} m-hide hasEvents`}
            onClick={onClose}
          />

          <span onClick={onClose} className={`${stl.cancel} m-show`}>
            {t("إلغاء", {
              ns: "web_common",
            })}
          </span>

          <AuthComponent
            isLogin={isLogin}
            setIsLogin={setIsLogin}
            isPopUp
            carCondition={carCondition}
            categoryId={categoryId}
            popUpVerifyCb={popUpVerifyCb}
            referral_location={event}
          />
        </div>
      )}
    </>
  );

  return canUseDOM ? (
    ReactDOM.createPortal(popUp, document.getElementById("BodyPortal"))
  ) : (
    <></>
  );
};

export default AuthPopUp;
