import stl from "./CardHeader.module.css";
import DiscountLabel from "../../../NewComponents/Cards/SearchCard/DiscountLabel/DiscountLabel";
import MarkitingLabel from "../../../NewComponents/Cards/SearchCard/MarkitingLabel/MarkitingLabel";
import { isFavoritePost } from "@/utils/auth";
import AddToFavoriteButton from "../../../../UiKit/Buttons/AddToFavorite/AddToFavoriteButton";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import { thumbUrl } from "@/utils/imagesHelpers";
import { useTranslation } from "@i18n";
import useIsMobile from "@hooks/useIsMobile";

const CardHeader = ({
  post,
  isEager,
  isCustomDesign,
  hideFavIcon,
  hideMarkitingLabel,
  hideDiscountLabel,
  TheImage = null,
}) => {
  const { t } = useTranslation(["web_search"]);
  const isMobile = useIsMobile();

  // TheImage is a prop that can be passed to the component to override the default image
  // Used on Homepage SuggestionsContainerOnline
  TheImage = TheImage
    ? TheImage
    : () => (
        <ProgressiveImage
          alt={post?.title ? post?.title : "car"}
          src={post.image_url}
          loading={isEager ? "eager" : "lazy"}
          fetchpriority={isEager ? "high" : "low"}
          progressiveSmallImage={thumbUrl(
            post?.image_url,
            "online-v1",
            isMobile ? "0x99" : "0x154"
          )}
          width={205}
          height={154}
        />
      );

  return (
    <div className={stl.cardHead}>
      {post?.discount && !isCustomDesign && !hideDiscountLabel ? (
        <DiscountLabel text={post.discount} />
      ) : (
        ""
      )}
      {post.campaign_id &&
      !post.limited_quantity &&
      !isCustomDesign &&
      !hideMarkitingLabel ? (
        <MarkitingLabel
          campaign={{
            id: post.campaign_id,
            txt: post.campaign_txt,
            bg_color: post.campaign_bg_color_hex,
          }}
        />
      ) : (
        ""
      )}
      {post.is_booked ? (
        <div className={stl.reservedCarTag}>
          <span>{t("محجوزة", { ns: "web_search" })}</span>
        </div>
      ) : (
        ""
      )}
      <TheImage />

      {!isCustomDesign && !hideFavIcon && (
        <AddToFavoriteButton
          isFavorite={isFavoritePost(+post.uniqueId)}
          postId={+post.uniqueId}
          authCarCondition={post.condition_id}
          className={stl.FavoriteButton}
          page="search"
          referral_location={"listing_page"}
          g4_data={post.g4_data_layer}
        />
      )}
    </div>
  );
};

export default CardHeader;
