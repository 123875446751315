import { Link } from "react-router-dom";
import { useLocation, useSearchParams } from "react-router-dom";
import CardBody from "./CardBody/CardBody";
import CardFooter from "./CardFooter/CardFooter";
import CardHeader from "./CardHeader/CardHeader";
import stl from "./UnbxdCard.module.css";
import { useDispatch } from "react-redux";
import { unbxdAnalytics } from "@/redux/actions/unbxdActions";
import { serverUrl } from "@globalConfig";
import { useLang } from "@/common/Contexts/LanguageContext";

const UnbxdCard = ({
  post,
  showGrantedPopup,
  metaData,
  isEager,
  isCustomDesign,
  hideFooterDeskMob,
  className,
  hideFavIcon,
  rank,
  page,
  isRecommendations,
  hideGrantedLabel,
  hideMarkitingLabel,
  hideDiscountLabel,
  TheImage = null,
  CustomTag = "h2",
  is_rec = false,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const lang = useLang();
  const isBrowseSearch = location.pathname.includes("/autos");

  const listing_type = searchParams.get("text")
    ? "search"
    : isBrowseSearch
    ? "browse"
    : "filter";

  let modifiedLink = post?.product_url?.substring(
    post?.product_url?.indexOf("/car")
  );

  if (lang === "en") {
    modifiedLink = "/en" + modifiedLink;
  }


  return (
    <Link
      to={`${modifiedLink}${isRecommendations ? `&rank=${rank}` : ""}${
        page && rank && listing_type
          ? `#page_number=${page}&product_position=${rank}&listing_type=${listing_type}`
          : ""
      }${is_rec ? `#is_rec=1&product_position=${rank}` : ""}`}
      state={{ screen_view_referral: "referralLink" }}
      className={`${stl.wrapper} ${className ? className : ""} `}
      onClick={() => {
        const params = {
          action: "click",
          query: searchParams.get("text") || "",
          pid: post.uniqueId,
          url: serverUrl + location.pathname + location.search,
          requestId: metaData?.requestId || "N.A",
          page: metaData?.category_path || "",
          page_type: metaData?.category_path ? "BOOLEAN" : "",
        };

        if (!isBrowseSearch || !metaData?.category_path) {
          // delete params.query;
          delete params.page;
          delete params.page_type;
        }

        if (isBrowseSearch && metaData?.category_path) {
          delete params.query;
        }

        dispatch(unbxdAnalytics(params));
      }}
    >
      <CardHeader
        post={post}
        isEager={isEager}
        isCustomDesign={isCustomDesign}
        hideFavIcon={hideFavIcon}
        hideMarkitingLabel={hideMarkitingLabel}
        hideDiscountLabel={hideDiscountLabel}
        TheImage={TheImage}
      />
      <CardBody
        post={post}
        showGrantedPopup={showGrantedPopup}
        isCustomDesign={isCustomDesign}
        hideGrantedLabel={hideGrantedLabel}
        CustomTag={CustomTag}
      />
      {!hideFooterDeskMob && (
        <CardFooter tags={post.tags} isCustomDesign={isCustomDesign} />
      )}
    </Link>
  );
};

export default UnbxdCard;
