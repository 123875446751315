import { getSimilarPosts } from "../../../../apis/posts/similar";
import { useTranslation } from "@i18n";
import { useEffect } from "react";
import stl from "./SimilarPosts.module.css";
import UnbxdCard from "../../../SearchComponent/UnbxdComponents/UnbxdCard/UnbxdCard";
import { useState } from "react";
import IsMobileContext from "../../../../common/Contexts/IsMobileContext";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import { thumbUrl } from "../../../../utils/imagesHelpers";
import useIsMobile from "@hooks/useIsMobile";
import { useLang } from "@/common/Contexts/LanguageContext";
import BoxLoading from "@/components/SearchComponent/components/BoxLoading";

const SimilarPosts = ({ postId }) => {
  const { t } = useTranslation(["web_post_view"]);
  const [similar, setSimilar] = useState([]);
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(true);

  const lang = useLang();
  useEffect(() => {
    postId &&
      getSimilarPosts(postId, 4).then((data) => {
        setSimilar(data);
        setLoading(false);
      });
    return () => setSimilar([]);
  }, [postId]);

  return similar?.length > 0 ? (
    <div className={stl.container}>
      <h2>
        {t("سيارات مشابهة", {
          ns: "web_post_view",
        })}
      </h2>
      <div className={stl.cardContainer}>
        {similar.length > 0 &&
          similar?.map((post, i) => {
            return (
              i < 3 && (
                <UnbxdCard
                  rank={i + 1}
                  is_rec
                  CustomTag="h3"
                  isEager={false}
                  imageSize="small"
                  key={post.id}
                  post={post}
                  showGrantedPopup={() => {}}
                  isOnline={true}
                  hideGrantedLabel
                  hideFooterDeskMob
                  // className={stl.postCard}
                  hideMarkitingLabel
                  hideDiscountLabel
                  TheImage={
                    !IsMobileContext
                      ? null
                      : () => (
                          <ProgressiveImage
                            waitUntilInteractive
                            alt={""}
                            src={thumbUrl(
                              post?.image_url,
                              "online-v1",
                              "0x300"
                            )}
                            loading="lazy"
                            fetchpriority="low"
                            width={110}
                            height={125}
                          />
                        )
                  }
                />
              )
            );
          })}
        {isMobile && similar.length > 3 && (
          <button
            className={[stl.mobileSimilar].join(" ")}
            onClick={() => {
              window.location.href =
                (lang == "ar" ? "" : "/en") + `/car/similar-cars/${postId}`;
            }}
          >
            {" "}
            {t("إعرض المزيد من السيارات", {
              ns: "web_post_view",
            })}
          </button>
        )}
      </div>
      {!isMobile && similar.length > 3 && (
        <button
          className={["whtBtn", stl.moreSimilar].join(" ")}
          onClick={() => {
            window.location.href =
              (lang == "ar" ? "" : "/en") + `/car/similar-cars/${postId}`;
          }}
        >
          {" "}
          {t("إعرض المزيد من السيارات", {
            ns: "web_post_view",
          })}
        </button>
      )}
    </div>
  ) : loading ? (
    <div className={stl.loaderContainer}>
      <div>
        <BoxLoading divHeight="276px" numberOfLoaders="3" />
      </div>
      <div>
        <BoxLoading divHeight="48px" numberOfLoaders="1" />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SimilarPosts;
