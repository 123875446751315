import { Link } from "react-router-dom";
import { createInternalUrlWithParams } from "../../../utils/common";
import { withTranslation } from "react-i18next";
import stl from "./SuggestionsContainer.module.css";
import { useLang } from "../../../common/Contexts/LanguageContext";
import UnbxdCard from "../../SearchComponent/UnbxdComponents/UnbxdCard/UnbxdCard";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import { thumbUrl } from "../../../utils/imagesHelpers";
import useIsMobile from "@hooks/useIsMobile";

const SuggestionsContainerOnline = (props) => {
  const isMobile = useIsMobile();
  const lang = useLang();

  return (
    <>
      <div className="SuggestionsContainerOnline">
        <div className={["container", stl.revampedHome].join(" ")}>
          <h2 className={["big", stl.revampedTitle].join(" ")}>
            {props.title}
          </h2>

          <div
            className={[stl.allCarsResult, stl.allCarsResultRevamped].join(" ")}
          >
            {props.similarPosts.map((post, i) => {
              return (
                <UnbxdCard
                  isEager={false}
                  imageSize="small"
                  key={post.id}
                  post={post}
                  showGrantedPopup={() => {}}
                  isOnline={true}
                  hideFavIcon
                  hideGrantedLabel
                  hideFooterDeskMob
                  className={stl.postCard}
                  hideMarkitingLabel
                  hideDiscountLabel
                  TheImage={
                    !isMobile
                      ? null
                      : () => (
                          <ProgressiveImage
                            waitUntilInteractive
                            alt={""}
                            src={thumbUrl(
                              post?.image_url,
                              "online-v1",
                              "0x154"
                            )}
                            loading="lazy"
                            fetchpriority="low"
                            width={110}
                            height={125}
                          />
                        )
                  }
                />
              );
            })}
          </div>
          <Link
            to={createInternalUrlWithParams(
              "/filters",
              {
                ["condition_id"]: props.isNew ? 1 : 0,
              },
              lang,
              {
                referral_location: "Home",
                browse_variant: "view_all",
                variant_value: "view all",
              }
            )}
            state={{ search_type: "homepage_listing" }}
            className={[
              ["redBtn", "moreSameCar", stl.showMorwBtn].join(" "),
            ].join(" ")}
          >
            {props.btnTxt}
          </Link>
        </div>
      </div>
    </>
  );
};

export default withTranslation(["web_home"])(SuggestionsContainerOnline);
